import Sortable from "sortablejs";

const ready = function () {
  const el = $(".sortable")[0];
  if (el) {
    let sortable;
    return (sortable = Sortable.create(el, {
      handle: "h4",
      onUpdate(evt) {
        const item = $(evt.item);
        return $.post("/products/producing_sort", {
          item: item.data("id"),
          position: item.index(),
        });
      },
    }));
  }
};
$(document).on("turbolinks:load", () => ready());
jQuery(() => ready());
