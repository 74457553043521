import { productSelect2 } from "./utils"
const ready = function () {

  $.get("/products.json", function (data) {
    window.products = data.results;
    productSelect2($(".product-select"), data.results);
    const typeahead = $(".typeahead").typeahead({
      showHintOnFocus: true,
      displayText(item) {
        return item.text;
      },
      source: data.results,
      afterSelect(item) {
        return (location.href = `/products?q[id_eq]=${item.id}`);
      },
    });
    if (typeahead.hasClass("home")) {
      return typeahead.focus();
    }
  });
  return $(".lazy-product-select").lazy_select();
};

$(document).on("turbolinks:load", () => ready());
jQuery(() => ready());


document.addEventListener('vanilla-nested:fields-added', function (e) {
  const productSelectElm = $(e.detail.added).find(".product-select");
  if (productSelectElm.length && window.products) {
    productSelect2(productSelectElm, window.products);
  }
})