import "./vendor/js/jquery.ui.widget";
import "./vendor/js/jquery.iframe-transport";
import "./vendor/js/jquery.fileupload";

jQuery(function () {
  $(".add-attach").click(function (e) {
    e.preventDefault();
    return prepare_attach(
      $($(this).data("attach")).appendTo(
        $('<div class="col-xs-6 col-md-3">').appendTo($(".attaches"))
      )
    );
  });
  $(".attaches").on("click", ".remove", function (e) {
    e.preventDefault();
    return $(this).closest(".attach").parent().remove();
  });

  var prepare_attach = function (self) {
    const bar = self.find(".progress-bar");
    const url_field = self.find("input.url");

    const set_progress = function (progress) {
      const percent = progress + "%";
      bar.css("width", percent);
      return bar.text(percent);
    };

    self.find(".fileupload").fileupload({
      formData: null,
      dataType: "json",
      add(e, data) {
        bar.parent().removeClass("hide");
        bar.addClass("active");
        set_progress(0);
        return data.submit();
      },

      done(e, data) {
        self.find("input.name:not([readonly])").val(data.result.name);
        url_field.val(data.result.url);

        set_progress(100);
        return bar.removeClass("active");
      },

      progressall(e, data) {
        const progress = parseInt((data.loaded / data.total) * 90, 10);
        return set_progress(progress);
      },
    });

    if (url_field.val()) {
      bar.parent().removeClass("hide");
      return set_progress(100);
    }
  };

  return $(".attach").each(function () {
    return prepare_attach($(this));
  });
});
